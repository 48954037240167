var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('v-col',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"label":"Search Material"},model:{value:(_vm.searchMaterialTitle),callback:function ($$v) {_vm.searchMaterialTitle=$$v},expression:"searchMaterialTitle"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","fab":"","link":"","color":"primary"},on:{"click":function($event){return _vm.setMaterialForm(true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Add Material")])])],1)],1)],1),_c('v-list',{staticClass:"mt-0 pt-0",staticStyle:{"overflow-y":"scroll"},attrs:{"two-line":"","color":"theme-background","max-height":"300px"}},[_vm._l((_vm.getUserMaterials.filter(
        item =>
          item.title
            .trim()
            .toLowerCase()
            .indexOf(_vm.searchMaterialTitle) !== -1 ||
          item.description
            .trim()
            .toLowerCase()
            .indexOf(_vm.searchMaterialTitle) !== -1
      )),function(item,index){return [_c('v-list-item',{key:item._id,attrs:{"link":""},on:{"click":function($event){return _vm.setMaterialOfCurrentGeometry(item)}}},[_c('v-list-item-avatar',{attrs:{"color":item.color}},[_c('span',{staticClass:"white--text headline"},[_vm._v(_vm._s(item.title[0]))])]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.title)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(item.description)}})],1)],1),_c('v-divider',{key:item._id + index,attrs:{"inset":""}})]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }